#layoutSidenav {
  display: flex;
  transition: all 0.3s ease;
}

#layoutSidenav_nav {
  width: 250px;
  transition: all 0.3s ease;
}

#layoutSidenav.collapsed #layoutSidenav_nav {
  width: 0;
}

#layoutSidenav_content {
  flex-grow: 1;
  transition: all 0.3s ease;
  margin-left: 250px; /* Adjust according to the sidebar width */
}

#layoutSidenav.collapsed #layoutSidenav_content {
  margin-left: 0;
}
