.logosetdark {
  margin-top: 15px;
  width: 200px;
  height: 50px;
  background: url(../../../assets/img/logodark.png) no-repeat top center;
  display: inline-block;
}
.logosetdark:hover {
  background: url(../../../assets/img/logohoverdark.png) no-repeat top center;
}

.custom-shadow {
  box-shadow: 0 0 10px rgb(255, 255, 255); /* Adjust values as needed */
}
html, body {
  height: 100%;
  margin: 0;
}
.backgroud{
  width: 100%;
  height: 100vh;
}
